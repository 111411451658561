import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
// @ts-ignore
import { withTranslation } from "react-i18next";
import {
  createStyles,
  createTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import Select from "react-select"

import { t } from "i18next";
import { RouteComponentProps } from "react-router";
import CustomButton from "./CustomButton";
import { getLoggedUserRole } from "./GetLoggedUserData";
type MyProps = { 
    disabled?:boolean;
    classes:any;
    handleConfirmCSvDialog:()=>void;
    handleSelectTemplateChange:(event:any)=>void;
    openCSVDialog:boolean;
    handleopenCSVDialog:()=>void;
    selectedtemplateOption:string;
    btnName:string;
    templateList:any[];
    selectedTemplateID:string;
    handleTemplateIDChange:(selectedoption:any)=>void
};


const useStyles = createStyles({
  dialogWrapper:{
      "& [role='dialog']":{
          borderRadius: '14px',
          width: '500px',
          hieght:"auto"
      }
  },
  headingStyle: {
      textAlign: 'center',
      '& h2': {
          fontWeight: 700,
          
      },
      fontSize: "16px"
  },
  paraStyle:{
      textAlign: 'center',
  },
  btnWrapper:{
      justifyContent: 'end',
      '& button': {
          padding: '15px 0px',
          width: '150px',
          borderColor: '#3b8da2',
          borderRadius: '15px',
      }
  },
  primaryBtnStyle: {
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform": "none",
    "color": "white"
  },
  cancelBtnStyle: {
    background: "linear-gradient(to bottom, red, #C87674)",
    "text-transform": "none",
    "color": "white",
    borderRadius: "10px"
  },
  textInputStyle: {
      marginTop: "10px",
      marginBottom: "10px"
  },
  dialogTitle: {
      textAlign: "left",
      fontSize: "16px",
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
  },
  subHeading: {
      fontSize: "16px",
      margin: "0 4px"
  },
  confirmBtn: {
      borderRadius: '8px',
  },
  disableBtn: {
      color: "#fff",
      borderRadius: '8px',
      background: '#757575'
  },
  downloaIcon: {
    cursor: "pointer"
},
})

export class CSVImportDetails extends React.Component<MyProps> {

  render() {
    // @ts-ignore
    const { 
      classes
      } = this.props;

   

    return (getLoggedUserRole() === "Admin"|| getLoggedUserRole() === "Operations")  && (
      <div data-test-id="customerCsvImport">
        <CustomButton
        disabled={this.props.disabled}
        btnText={t("export_csv")}
        onClick={this.props.handleopenCSVDialog}
         bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
         textColor=""
        />
  <Dialog
        fullWidth
        open={this.props.openCSVDialog}
        onClose={()=>this.props.handleopenCSVDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // maxWidth="xs"
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title">
            <Typography  style={{fontWeight:"bold",textAlign:"center"}}>
              {t("select_or_create_template")}
         </Typography>
        </DialogTitle>
        <DialogContent>
            <Grid container md={12}>
            <RadioGroup
    row={false}
    value={this.props.selectedtemplateOption}
    onChange={this.props.handleSelectTemplateChange}
>
    <FormControlLabel
        value={'Create a new template'}
        control={<Radio color="primary" />}
        label={<Typography style={{ fontWeight: this.props.selectedtemplateOption === 'Create a new template' ? 'bold' : 'normal' }}>{t("create_new_template")}</Typography>}
    />
    <FormControlLabel
        value={'Select from existing template'}
        control={<Radio color="primary" />}
        label={<Typography style={{ fontWeight: this.props.selectedtemplateOption === 'Select from existing template' ? 'bold' : 'normal' }}>{t("select_from_existing_template")}</Typography>}
    />
</RadioGroup>

                        <div style={{width:"100%",marginTop:"20px",marginBottom:"20px",zIndex:99}}>

                        <Select
                        menuPosition="fixed"
                          isDisabled={this.props.selectedtemplateOption !== 'Select from existing template'}
                          data-test-id="assignee"
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={this.props.handleTemplateIDChange}
                          value={this.props.selectedTemplateID}
                          options={this.props.templateList}
                          defaultValue={""}
                          placeholder=""
                          styles={{
                            menu: (provided: any) => ({
                              ...provided,
                              maxHeight: '108px',
                            }),
                            menuList: (provided: any) => ({
                              ...provided,
                              maxHeight: '108px',
                              overflowY: 'auto',
                            }),
                          }}
						/>
                        </div>

            </Grid>
            <div style={{display:"flex",justifyContent:"end",gap:"15px",marginTop:"27px",paddingBottom:"12px"}}>
            <CustomButton    
          btnText={t("cancel")}
        onClick={this.props.handleopenCSVDialog}
         bgColor={"white"}
         textColor="black"
      />
          <CustomButton
             disabled={this.props.selectedTemplateID==="" && this.props.selectedtemplateOption==="Select from existing template"}
             btnText={t("confirm")}
             onClick={this.props.handleConfirmCSvDialog}
              bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
              textColor="white" />
            </div>

        </DialogContent>
     
      </Dialog>
      </div>
    );
  }
}



export default withStyles(useStyles)(withTranslation()(CSVImportDetails));
