// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
// @ts-ignore
import { withTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import { t } from "i18next";
import Select from "react-select";
import ErrorIcon from "@material-ui/icons/Error";
import CustomButton from "./CustomButton";
import LabelWithIcon from "./LabelWithIcon";
import {
  createStyles,
} from "@material-ui/core/styles";
import Toster from "./Toster";

type MyProps = {
  alertType:string;
  alertMessage:string;
  showAlert:boolean;
  handleShowAlert:()=>void;
  classes?: any;
  columnList:any[];
  addColumn:()=>void;
  removeColumn:(columnIndex:number)=>void;
  handleCreatetemplate:()=>void;
  openCreateTemplatePopUp:boolean;
  handleSaveTemplatePopUp:()=>void;
  openSaveTemplatePopUp:boolean;
  addEncoding:(index: string) => void;
  removeEncoding:(columnIndex: string, encodingIndex: string | number) => void;
  updateEncodingValue:(columnIndex: string, encodingIndex: string | number,fieldName: string, value: any)=>void;
  columnNamesList:{value:string,label:string}[];
  handleColumnNameChange:( selectedColumnName:string,id:string|number,fieldName:string)=>void;
  selectedColumnName:{value:string,label:string};
  handleDragStart:any;
  handleDragOver:any;
  handleDragEnd:any;
  newTemplateName:string;
  handleTemplateNameChange:(event:any)=>void;
  handleCreateNewTemplate:()=>void;
  saveTemplate:()=>void;
  isExistingTemplate:any;
  handleDownloadfile:()=>void;
  templteColumnList:any[];
  handleTemplateIDChange:(selectedOption:any)=>void;
  selectedTemplateId:any;
};


const TempalateForm = (index:number,
  removeColumn:any,
  addEncoding:any,
  encodingList:any[],
  removeEncoding:any,
  columnId:string,columnNamesList:any[],handleColumnNameChange:any,
  column:any,updateEncodingValue:any) => {
  return (
    !column._destroy && <div
      style={{
        background: "white",
        marginTop: "30px",
        padding: "12px",
        borderRadius: "12px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            gap: "15px",
            margin: "0px 7px",
            alignItems: "center",
          }}
        >
          <span className="icon">
            <DragIndicatorIcon style={{ color: "grey" }} />
          </span>
        </div>
        <span>
          <ClearIcon onClick={()=>removeColumn(index)} style={{ color: "grey",cursor:"pointer" }} />
        </span>
      </div>

      <Grid container md={12} spacing={2} style={{ margin: "20px 0px" }}>
        
        <Grid item md={4}>
        <span style={{ display: "flex", gap: "10px", alignItems: "center",paddingBottom:"10px" }}>
            <span style={{fontSize:"14px"}}>{t("select_column")}</span> 
          </span>
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            data-test-id="assignee"
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            value={{value:column.column_name,label:column.column_name}}
            placeholder=""
            onChange={(event: any) => handleColumnNameChange(event, columnId,"column_name")}
            options={columnNamesList}
            styles={{
              groupHeading: (base: any) => ({
                ...base,
                color: "#000",
                "font-weight": "bold",
              }),
            }}
          />
        </Grid>
        <Grid container item md={4}>
        <span style={{ display: "flex", gap: "10px", alignItems: "center",paddingBottom:"10px" }}>
            <span style={{fontSize:"14px"}}>{t("column_name_on_export_optional")}</span>
          </span>
          <TextField 
            variant="outlined"
            style={{width:"100%"}}
            value={column.column_name_on_export}
            placeholder=""
            size="small"
            onChange={(event) => handleColumnNameChange(event.target.value, columnId,"column_name_on_export")}
            />
        </Grid>
      </Grid>
      <div
        className="encoingDi"
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "15px 6px",
        }}
      >
        <span>{t("encoding_optional")}</span>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={()=>addEncoding(index)}
          style={{
            textTransform: "none",
            backgroundColor: "white",
            color: "#007E98",
            boxShadow: "none",
            fontSize: 17,
            padding: 0,
            fontWeight: 400,
          }}
        >
          <AddIcon style={{ width: 15, height: 15 }} />
          {t("add_encoding")}
        </Button>
      </div>
      {encodingList?.map((ele:any)=><span key={ele.id}><RenderEncodingUi encodingListElement={ele} updateEncodingValue={updateEncodingValue} encodingElementId={ele.id} columnId={columnId} removeEncoding={removeEncoding}/></span>)}
    </div>
  );
};
const SaveTemplatePopUp=({classes,openSaveTemplatePopUp,handleSaveTemplatePopUp,saveTemplate}:any)=>{
    return (
        <Dialog
      fullWidth
      open={openSaveTemplatePopUp}
      onClose={handleSaveTemplatePopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      className={classes.savetemplateWrapper}
    >
            <>
              <DialogTitle id="alert-dialog-title">
                <div>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600, textAlign: "center" }}
                  >
                    {t("save_template")}
                  </Typography>
                </div>
              </DialogTitle>
              <DialogContent>
                <div  style={{marginTop:"7px"}}>
                  <Typography style={{width:"100%",fontSize:"14px",color:"grey",display:"block",textAlign:"center"}}>
                    {"Are you sure you want to change the template?"}
                  </Typography>
                  <Typography style={{width:"100%",fontSize:"14px",color:"grey",textAlign:"center",marginBottom:"50px"}}>
                    {"This might affect other users."}
                  </Typography>
            
                </div>
              </DialogContent>
              <DialogActions style={{justifyContent:"center",marginBottom:"15px",gap:"15px"}}>
                <CustomButton
                  btnText={t("cancel")}
                  onClick={()=>handleSaveTemplatePopUp()}
                  bgColor={"white"}
                  textColor={"#2d6f8f"}
                  data-test-id="create_tasks_button"
                />
                <CustomButton
                  btnText={t("save")}
                  onClick={()=>saveTemplate()}
                  bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
                  textColor={"#fff"}
                  data-test-id="create_tasks_button"
                />
              </DialogActions>
            </>
    </Dialog>
    )
}
const RenderEncodingUi=({removeEncoding,encodingElementId,columnId,encodingListElement,updateEncodingValue}:any)=>{
    return (
      <Grid container md={12} alignItems="center" style={{display:"flex",justifyContent:"space-between",marginTop:"12px"}} >
        <Grid container  item md={5}>
        <TextField onChange={(e)=>updateEncodingValue(columnId,encodingElementId,"encoding_from",e.target.value)} size="small" style={{width:"100%"}} variant="outlined" placeholder="" value={encodingListElement.encoding_from} />
        </Grid>
        <Grid item alignContent="center">
          <span>{t("encode_to")}</span>
        </Grid>
        <Grid container item md={5} >
        <TextField onChange={(e)=>updateEncodingValue(columnId,encodingElementId,"encoding_to",e.target.value)} size="small" style={{width:"100%"}} variant="outlined" placeholder="" value={encodingListElement.encoding_to} />
        </Grid>
        <Grid item>
          <ClearIcon style={{ color: "grey",marginRight:"10px",cursor:"pointer" }} onClick={()=>removeEncoding(columnId,encodingElementId)} />
        </Grid>
      </Grid>
    );

}

const CreateTemplatePopup = ({classes,handleCreateNewTemplate,openCreateTemplatePopUp,handleopenCreateTemplatePopUp,newTemplateName,handleTemplateNameChange}:any) => {
  return (
    <Dialog
      fullWidth
      open={openCreateTemplatePopUp}
      onClose={handleopenCreateTemplatePopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      className={classes.dialogWrapper}
    >
              <>
              <DialogTitle id="alert-dialog-title">
                <div>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600, textAlign: "center" }}
                  >
                    {t("create_template")}
                  </Typography>
                </div>
              </DialogTitle>
              <DialogContent>
                <Grid container md={12} spacing={0}>
                  <Typography style={{margin:"20px 0px",width:"100%",textAlign:"center",padding:"0px 6px",fontSize:"14px"}}>
                    {t("create_template_q")}
                  </Typography>
                  <Grid item md={12} style={{margin:"15px 0px"}}>
                  <LabelWithIcon label={t("template_name")} /> 
                <TextField
                  data-test-id="txtInputEmail"
                  placeholder={""}
                  variant="outlined"
                  fullWidth={true}
                  value={newTemplateName}
                  onChange={(e)=>handleTemplateNameChange(e)}
                />
              </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{justifyContent:"space-around",paddingBottom:"15px",paddingTop:"37px"}}>
                <CustomButton
                  btnText={t("cancel")}
                  onClick={()=>handleopenCreateTemplatePopUp()}
                  bgColor={"white"}
                  textColor={"#2d6f8f"}
                  data-test-id="create_tasks_button"
                />
                <CustomButton
                disabled={newTemplateName===""}
                  btnText={t("create")}
                  onClick={()=>handleCreateNewTemplate()}
                  bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
                  textColor={"#fff"}
                  data-test-id="create_tasks_button"
                />
              </DialogActions>
            </>
    </Dialog>
  );
};

// Customizable Area End
export class CSVExport extends  React.Component<MyProps> {
  constructor(props: MyProps) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
   const {classes}=this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <div style={{marginBottom:"150px"}}>
            <Toster
								data-test-id="toaster"
								tosterText={this.props.alertMessage}
								showToster={this.props.showAlert}
								tosterType={this.props.alertType}
								handleCloseToster={() => this.props.handleShowAlert()}
						  />
        <div>
          <Typography style={{ fontWeight: 600 }}>{t("csv_export")}</Typography>
          <div className={classes.createTemplateDiv}>
           {this.props.isExistingTemplate ? <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            value={this.props.selectedTemplateId}
            placeholder=""
            onChange={(option:any)=>this.props.handleTemplateIDChange(option)}
            options={this.props.templteColumnList}
            styles={{
              container: (provided: any) => ({
                ...provided,
                width: '200px',
              }),
              groupHeading: (base: any) => ({
                ...base,
                color: "#000",
                "font-weight": "bold",
              }),
            }}
          />:<span />}
            { !this.props.isExistingTemplate  ?<CustomButton
                btnText={t("create_template")}
                onClick={this.props.handleCreatetemplate}
                textColor={"white"}
                bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
              />
              :
              <CustomButton
                btnText={t("save_template")}
                onClick={this.props.handleSaveTemplatePopUp}
                textColor={"white"}
                bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
              />}
            </div>

            { this.props.columnList?.map((ele:any,index:number)=> <span draggable onDragStart={() => this.props.handleDragStart(index)}
          onDragOver={() => this.props.handleDragOver(index)}
          onDragEnd={this.props.handleDragEnd} key={index}>{TempalateForm(ele.id,
            this.props.removeColumn,
            this.props.addEncoding,
            ele.encodingList,
            this.props.removeEncoding,
            ele.id,
            this.props.columnNamesList,
            this.props.handleColumnNameChange,
            ele,this.props.updateEncodingValue)}
            
            </span>)}
            <button
              onClick={this.props.addColumn}
              style={{background:"transparent"}}
              className={classes.addButton}
            >
              <AddIcon style={{ width: 15, height: 15,marginRight:"2px" }} />
              {t("add_column")}
            </button>
          </div>
          <CreateTemplatePopup classes={this.props.classes} handleCreateNewTemplate={this.props.handleCreateNewTemplate} newTemplateName={this.props.newTemplateName} handleTemplateNameChange={this.props.handleTemplateNameChange} openCreateTemplatePopUp={this.props.openCreateTemplatePopUp} handleopenCreateTemplatePopUp={this.props.handleCreatetemplate}/>
        <SaveTemplatePopUp classes={this.props.classes} saveTemplate={this.props.saveTemplate} handleSaveTemplatePopUp={this.props.handleSaveTemplatePopUp} openSaveTemplatePopUp={this.props.openSaveTemplatePopUp}/>
     {this.props.isExistingTemplate && 
     <div style={{position:"absolute",bottom:"0",backgroundColor:"white",textAlign:"right",left:"0",marginTop:"100px",right:"0",padding:"10px"}}>
        <CustomButton
        btnText={t("download_file")}
        onClick={()=>this.props.handleDownloadfile()}
        bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
        textColor={"white"}
        data-test-id="download_csv_button"
        />
        </div>
     }
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const useStyles = createStyles({
  dialogWrapper:{
      "& [role='dialog']":{
          borderRadius: '14px',
          width: '380px'
      }
  },
  savetemplateWrapper:{
    "& [role='dialog']":{
        borderRadius: '14px',
        width: '380px'
    }
},
  headingStyle: {
      textAlign: 'center',
      '& h2': {
          fontWeight: 700,
          
      },
      fontSize: "16px"
  },
  paraStyle:{
      textAlign: 'center',
  },
  createTemplateDiv:{
    marginTop:"20px",
    display: "flex",
    justifyContent: "space-between",
    border: "none",
  },
  addButton:{
    display:"flex",
    alignItems:"center",
    textTransform: "none",
    color: "#007E98",
    boxShadow: "none",
    fontSize: 17,
    padding: 0,
    fontWeight: 400,
    float: "right",
    background: "transparent",
    marginTop: "15px",
    border:"none",
    cursor:"pointer"
  }
})

export default withStyles(useStyles as any)(
  withTranslation()(CSVExport)
);