// Customizable Area Start
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography, DialogTitle, DialogContent, Grid, DialogActions } from "@material-ui/core";
import LabelWithIcon from './LabelWithIcon';
import { InputOption, GroupHeading } from './RequestFilterDialog.web';
import Select from 'react-select';
import TextInput from "./TextInput";
import FileUploadMulti from "./FileUploadMulti";
import {GetLoggedUserData} from "./GetLoggedUserData";
import { ErrorMessage } from "formik";


type TaskFormType = {
	classes: any;
	update: boolean;
	values: any;
	state: any;
	customerList: any[];
	assigneeList: any[];
	setFieldValue: (name: string, event: any) => void;
	handleTaskIdDialog: (testid: string, dialogStatus: boolean) => void;
	handleSubmit: (data: any) => void;
	getFilePath: (data: any[]) => any[];
	handleRemoveFileData: (id: string | number) => void;
	resetForm: () => void
	completeTask: (values: any) => void
}

const TaskForm = ({
	classes,
	update,
  state,
	values,
	customerList,
	assigneeList,
	setFieldValue,
	handleTaskIdDialog,
	handleSubmit,
	getFilePath,
	handleRemoveFileData,
	resetForm,
	completeTask,
}: TaskFormType) => {
  const {t} = useTranslation();

  const [loggedUsedData, setLoggedUsedData] = React.useState(GetLoggedUserData());

	const fromRole = (): boolean => {
		if(typeof values.assignee.value === 'number') {
			return (loggedUsedData.role?.toLowerCase() === values?.assignee?.label?.toLowerCase());
		} else {
			return (loggedUsedData.role?.toLowerCase() === values?.assignee?.value?.toLowerCase());
		}
	}

  const checkEditAccess = (): boolean => {
    return (loggedUsedData.id === values?.assigned_by?.id)
  }

  const checkCompleteAccess = (): boolean => {
    return (loggedUsedData.id === values?.assignee?.value || fromRole())
  }

  const getEditTitle = (): string => {
    return checkEditAccess() ? t('Update_task') : t('view_task')
  }

	return (
		<>
			<DialogTitle id="alert-dialog-title">
					<div className={classes.dialogTitle}>
						<span className={classes.headingStyle}>{update ? getEditTitle() : t('create_task')}</span>
					</div>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<LabelWithIcon label={t('task_name')} />
						<TextInput
							id="task_name"
							name="task_name"
							type="text"
							fullWidth
							data-test-id="task_name"
							onChange={(e: any) => setFieldValue(`task_name`, e.target.value)}
						/>
						<ErrorMessage
								data-test-id="task_name_error"
								name={`task_name`}
								className="field-error"
								render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
						/>
					</Grid>
					<Grid item xs={6}>
						<LabelWithIcon label={t('assignee')} />
						<Select
								data-test-id="assignee"
								closeMenuOnSelect={true}
								hideSelectedOptions={false}
								className={classes.selectorClass}
								onChange={(options: { group: any; }) => {
									setFieldValue(`assignee`, options)
									setFieldValue(`assignee_type`, options.group)
								}}
								value={values.assignee || assigneeList[0]?.['options'][0]}
								options={assigneeList}
								defaultValue={assigneeList[0]?.['options'][0]}
								components={{
									GroupHeading: GroupHeading,
									Option: InputOption,
								}}
								styles={{
										groupHeading: (base: any) => ({
											...base,
												color: '#000',
												'font-weight': 'bold',
										}),
								}}
						/>
						<ErrorMessage
								data-test-id="assignee_error"
								name={`assignee`}
								className="field-error"
								render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
						/>
					</Grid>
					<Grid item xs={6}>
						<LabelWithIcon label={t('customer_optional')} />
						<Select
								data-test-id="customer_optional"
								closeMenuOnSelect={true}
								hideSelectedOptions={false}
								className={classes.selectorClass}
								value={values.customer}
								onChange={(options: any) => {
									setFieldValue(`customer`, options)
								}}
								options={[...customerList]}
								components={{
									Option: InputOption,
								}}
						/>
					</Grid>
					<Grid item xs={12}>
						<LabelWithIcon label={t('attachment_optional')} />
						<FileUploadMulti
								fileName={"attachment"}
								filePath={getFilePath(values?.attachment || [])}
								inputName="attachment"
								data-test-id="attachment_optional"
								onChange={(e: any) => setFieldValue(`attachment`, [...values.attachment, ...e.target.files])}
								onRemove={({id, fileData}: {id: number, fileData: any[]}) => {
									id && handleRemoveFileData(id)
									setFieldValue('uploadedAttachments', fileData)
								}}
								maxFilesNumber={5}
								eachFileSizeInMB={10}
						/>
					</Grid>
					<Grid item xs={12}>
						<LabelWithIcon label={t('description')} />
						<TextInput
							id={"description"}
							name="description"
							fullWidth
							type="text"
							multiline={true}
							minRows={4}
							data-test-id="description"
							onChange={(e: any) => setFieldValue(`description`, e.target.value)}
						/>
						<ErrorMessage
								data-test-id="description_error"
								name={`description`}
								className="field-error"
								render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className={classes.buttonWrapper}>
				<Button variant="outlined"
						className={classes.cancelBtnStyle}
						data-test-id="cancel_button"
						onClick={() => {
							resetForm();
							handleTaskIdDialog("", false)
						}}>
						{t('cancel')}
				</Button>

				{(checkEditAccess() || !update) && <Button variant="contained"
						data-test-id="create_button"
						style={{ borderRadius: "8px" }}
						className={classes.primaryBtnStyle}
						onClick={() => {
							handleSubmit(values);
						}}
						color="primary" autoFocus
				>
					{update ? t('Update') : t('create')}
				</Button>}

        {update && (!checkEditAccess() || checkCompleteAccess()) && (
          <Button
            variant="contained"
            data-test-id="complete_button"
            style={{ borderRadius: "8px" }}
            className={classes.primaryBtnStyle}
            onClick={() => {
							completeTask(values);
						}}
            color="primary" autoFocus
            disabled={update && !checkCompleteAccess()}
          >
            {t('complete')}
          </Button>
        )}
			</DialogActions>
		</>
	)
}

export default TaskForm;