import React from "react";
import {
    Tab,
    Box,
    Card,
    OutlinedInput,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Grid,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import FilterAltSharpIcon from "@material-ui/icons/FilterList";
import { Close } from '@material-ui/icons';
import Select from 'react-select';

import moment from "moment";
import { withTranslation } from 'react-i18next';
import { createTheme, withStyles } from "@material-ui/core/styles";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import AnalyticsListingController from "../../../../blocks/visualanalytics/src/AnalyticsListingController";
import TransactionTable from "./TransactionTable";
import { getLoggedUserRole } from "../GetLoggedUserData";
import InsuranceInsurerTable from "./InsuranceInsurerTable";
import SalesTable from "./SalesTable";
import InsurerBalanceTable from "./InsurerBalanceTable";
import SearchBar from "../SearchBar/SearchBar";
import DateSelector from "../DateSelector";
import LabelWithIcon from '../LabelWithIcon';
import { InputOption } from '../RequestFilterDialog.web';


const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

const webStyle = {
    analyticsTabs: {
        fontWeight: 500,
        "text-transform": "none"
    },
    activeTab: {
        fontWeight: 600,
        "text-transform": "none",
    }
}
export class TablesTab extends AnalyticsListingController {

    getFilterText = (value: string) => {
        let selectedFilter = value.split('_').join(' ').toLowerCase();
        return selectedFilter.charAt(0).toUpperCase() + selectedFilter.substring(1)
    }

    render() {
        const { t, classes } = this.props;

        const {
            transactionPage,
            transactionTotalPage,
            transactionList,
            salesPage,
            salesTotalPage,
            salesTableList,
            insuranceTableList,
            insurerTableList,
            insurerPage,
            insurerTotalPage,
            insurerRowPerPage,
            insurerBalanceTableList,
            insurerBalanceTotalPage,
            insurerBalancePage,
            insurerBalanceRowPerPage,
            insurerBalanceLoading
        } = this.state

        return (
            <div data-test-id="TablesTab">
                <div className={classes.alertDivWithSearchTop}>
                    {(this.state.activeTabIndex=== "1" || this.state.activeTabIndex=== "4") ? <div className="searchBar-container">
                        <SearchBar
                        value={this.state.searchQuery}
                        data-test-id="customer-filter-searchbar"
                        searchQuery={this.handleSearchQuery}
                        />
                    </div>:<span></span>}
                    <div className={classes.rightPanel}>
                        <div className={classes.toggle}>
                        {this.state.activeTabIndex !== '5' ? <OutlinedInput
                            className={classes.filterInput}
                            data-test-id="filter_input"
                            value={this.getFilterText(this.state.filterBy) || 'Month to date'}
                            fullWidth
                            type={"text"}
                            onClick={() => this.setState({ showFilterSection: true })}
                        /> : <Button
                                data-test-id="filterBtn"
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                variant="contained"
                                color="primary"
                                className={classes.btnText}
                                onClick={this.handleFilterDialog}
                            >{t("filter")} {"  "} <FilterAltSharpIcon /></Button>}
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.filterDialog}
                    onClose={this.handleFilterDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                    className={classes.dialogWrapper}
                >
                    <DialogTitle id="alert-dialog-title">
                        <div className={classes.dialogTitle}>
                            <div>
                                <span className={classes.headingStyle}>{t('filter')}</span>
                            </div>
                            <div style={{ display: "flex" }}
                                onClick={this.clearInsuranceBalanceFilter}
                                className={classes.downloadIcon}
                                role="button"
                                tabIndex={0}
                            >
                                <span className={classes.clearBtn}>{t('clear_all')}</span>
                                <Close className={classes.clearBtn} />
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <LabelWithIcon label={t('insurer')} />
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={(options: any) => this.handlerInsurerSelector(options)}
                                    options={this.state.insurerList}
                                    value={this.state.selectedInsurersData}
                                    components={{
                                        Option: InputOption,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.btnWrapper}>
                        <Button variant="outlined"
                            className={classes.cancelBtnStyle}
                            onClick={this.handleFilterDialog}>
                            {t('cancel')}
                        </Button>
                        <Button variant="contained"
                            style={{ borderRadius: "8px" }}
                            className={classes.modelPrimaryBtn}
                            onClick={() => this.filterInsurerBalance()}
                            color="primary"
                            autoFocus
                        >
                            {t('filter')}
                        </Button>
                    </DialogActions>

                </Dialog>
                {this.state.showFilterSection &&
                    <Grid dir="ltr" container className={classes.filterDiv} justifyContent="center">
                    <Grid item lg={3} md={5} style={{textAlign: 'center'}}>
                    {(this.getDateDisableState(this.state.filterBy, this.state.activeTabIndex)) && (
                        <>
                        <label htmlFor="startDate">{t('start_date')}:</label>
                        <DateSelector
                            value={this.state.startDate}
                            data-test-id="start_date"
                            disabled={this.getDateDisableState(this.state.filterBy, this.state.activeTabIndex)}
                            require={this.getDateDisableState(this.state.filterBy, this.state.activeTabIndex)}
                            onChange={(date: Date | null) => {
                            this.setState({startDate: moment(date).format('YYYY-MM-DD')})
                            }}
                        />
                        </>
                    )}
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item lg={3} md={5} style={{textAlign: 'center'}}>
                    {(this.getDateDisableState(this.state.filterBy, this.state.activeTabIndex)) && (
                        <>
                        <label htmlFor="endDate">{t('end_date')}:</label>
                        <DateSelector
                            data-test-id="end_date"
                            disabled={this.getDateDisableState(this.state.filterBy, this.state.activeTabIndex)}
                            require={this.getDateDisableState(this.state.filterBy, this.state.activeTabIndex)}
                            value={this.state.endDate}
                            onChange={(date: Date | null) => {
                            this.setState({endDate: moment(date).format('YYYY-MM-DD')})
                            }}
                        />
                        </>
                    )}
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item lg={3} md={5} >
                        <FormControl className={classes.mainRadioContent} component="fieldset">
                        <RadioGroup
                            data-test-id="filter_options"
                            aria-label="filterOptions"
                            name="filterOptions"
                            value={this.state.filterBy}
                            onChange={(e) => this.filterChange(e, false)}
                        >
                            <FormControlLabel value="month_to_date" control={<Radio color="primary" />} label={t('month_to_date')} />
                            <FormControlLabel value="quarter_to_date" control={<Radio color="primary" />} label={t('quarter_to_date')} />
                            <FormControlLabel value="year_to_date" control={<Radio color="primary" />} label={t('year_to_date')} />
                            {this.state.activeTabIndex !== "4" && (
                                <div>
                                <FormControlLabel
                                    value="last_month_quarter_year"
                                    control={<Radio color="primary" />}
                                    label={t('last_month_quarter_year')}
                                />
                                {this.state.filterBy === 'last_month_quarter_year' && (
                                    <RadioGroup
                                    aria-label="last-options"
                                    data-test-id="last_options"
                                    name="last-options"
                                    className={classes.childRadioButtons}
                                    value={this.state.filerCustomOption}
                                    onChange={(e) => this.filterChange(e, true)}
                                    >
                                    <FormControlLabel value="lastMonth" control={<Radio color="primary" />} label={t('last_month')} />
                                    <FormControlLabel value="lastQuarter" control={<Radio color="primary" />} label={t('last_quarter')} />
                                    <FormControlLabel value="lastYear" control={<Radio color="primary"  />} label={t('last_year')} />
                                    </RadioGroup>
                                )}
                                <FormControlLabel value="custom" control={<Radio color="primary" />} label={t('custom_date')} />
                                </div>
                            )}
                        </RadioGroup>

                        <div className={classes.filterApplyButtons} >
                            <Button data-test-id="cancel_button" variant="outlined" className={classes.clearBtnStyle} onClick={() => this.clearFilter()}>{t('clear')}</Button>
                            <Button data-test-id="filter_button" variant="contained" className={classes.filterSubmitBtn} onClick={() => this.applyFilter(this.state.activeTabIndex)}>{t('apply')}</Button>
                        </div>
                        </FormControl>
                        </Grid>
                    </Grid>
                }
                <div>
                    <Card variant="outlined" className={classes.tableSection} >
                        <TabContext value={String(this.state.activeTabIndex)}>
                            {(
                                (getLoggedUserRole() === "Admin") ||
                                (getLoggedUserRole() === "Finance")) &&
                                (
                                    <TabContext value={String(this.state.activeTabIndex)}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider', pl: 3, pt: 2, fontStyle: "bold" }}>
                                            <TabList
                                                data-test-id="tab_list"
                                                onChange={(e, newValue) => this.handleChange(e, newValue)}
                                                TabIndicatorProps={{
                                                    style: {
                                                        background: "linear-gradient(to right, #2d6f8f, #4fb7ba)",
                                                        height: "7px",
                                                        borderRadius: "50px",
                                                        textDecoration: "none"
                                                    }
                                                }}
                                            >
                                                <Tab label={t('transaction')} style={this.state.activeTabIndex === "1" ? webStyle.activeTab : webStyle.analyticsTabs} className="tabButtons" value="1" />
                                                <Tab label={t('insurance')} style={this.state.activeTabIndex === "2" ? webStyle.activeTab : webStyle.analyticsTabs} value="2" />
                                                <Tab label={t('insurer')} style={this.state.activeTabIndex === "3" ? webStyle.activeTab : webStyle.analyticsTabs} value="3" />
                                                <Tab label={t('sales')} style={this.state.activeTabIndex === "4" ? webStyle.activeTab : webStyle.analyticsTabs} value="4" />
                                                <Tab label={t('insurer_balance')} style={this.state.activeTabIndex === "5" ? webStyle.activeTab : webStyle.analyticsTabs} value="5" />
                                            </TabList>

                                        </Box>

                                        <TabPanel value="1">
                                            <TransactionTable
                                                {...this.props}
                                                currentPage={transactionPage}
                                                totalPage={transactionTotalPage}
                                                pageChange={this.handleTransactionPageChange}
                                                tableFor={'PolicyListing'}
                                                tableData={transactionList}
                                                transactionLoading={this.state.transactionLoading}
                                            />
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <InsuranceInsurerTable
                                                {...this.props}
                                                currentPage={1}
                                                totalPage={1}
                                                tableFor={'Insurance'}
                                                tableData={insuranceTableList}
                                                insuranceLoading={this.state.insuranceLoading}
                                            />
                                        </TabPanel>
                                        <TabPanel value="3">
                                            <InsuranceInsurerTable
                                                {...this.props}
                                                data-test-id="insurer_table"
                                                currentPage={insurerPage}
                                                totalPage={insurerTotalPage}
                                                pageChange={this.handleInsurerPageChange}
                                                handleRowPerPage={this.handleInsurerRowPerPage}
                                                perPage={insurerRowPerPage}
                                                tableFor={'Insurer'}
                                                tableData={insurerTableList}
                                                insuranceLoading={this.state.insuranceLoading}
                                            />
                                        </TabPanel>
                                        <TabPanel value="4">
                                            <SalesTable
                                                {...this.props}
                                                currentPage={salesPage}
                                                totalPage={salesTotalPage}
                                                pageChange={this.handleSalesPageChange}
                                                tableFor={'PolicyListing'}
                                                tableData={salesTableList}
                                                salesLoading={this.state.salesLoading}
                                            />
                                        </TabPanel>
                                        <TabPanel value="5">
                                            <InsurerBalanceTable
                                                {...this.props}
                                                data-test-id="insurer_balance_table"
                                                currentPage={insurerBalancePage}
                                                totalPage={insurerBalanceTotalPage}
                                                perPage={insurerBalanceRowPerPage}
                                                pageChange={this.handleInsurerBalancePageChange}
                                                tableFor={'InsurerBalance'}
                                                tableData={insurerBalanceTableList}
                                                insurerBalanceLoading={insurerBalanceLoading}
                                            />
                                        </TabPanel>
                                    </TabContext>
                                )}

                            {(
                                (getLoggedUserRole() === "Operations") ||
                                (getLoggedUserRole() === "Sales")) && (
                                    <TabContext value={String(this.state.activeTabIndex)}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider', pl: 3, pt: 2, fontStyle: "bold" }}>
                                            <TabList
                                                data-test-id="tab_list"
                                                onChange={(e, newValue) => this.handleChange(e, newValue)}
                                                TabIndicatorProps={{
                                                    style: {
                                                        background: "linear-gradient(to right, #2d6f8f, #4fb7ba)",
                                                        height: "7px",
                                                        borderRadius: "50px",
                                                        textDecoration: "none"
                                                    }
                                                }}
                                            >
                                                <Tab label={t('transaction')} style={this.state.activeTabIndex === "1" ? webStyle.activeTab : webStyle.analyticsTabs} className="tabButtons" value="1" />
                                            </TabList>

                                        </Box>

                                        <TabPanel value="1">
                                            <TransactionTable
                                                {...this.props}
                                                currentPage={transactionPage}
                                                totalPage={transactionTotalPage}
                                                pageChange={this.handleTransactionPageChange}
                                                tableFor={'PolicyListing'}
                                                tableData={transactionList}
                                                transactionLoading={this.state.transactionLoading}
                                            />
                                        </TabPanel>
                                    </TabContext>
                            )}
                        </TabContext>
                    </Card>
                </div>
            </div >
        )
    }
}

const useStyles = ({
    tabButtons: {
        "text-transform": "none"
    },
    primaryBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "50px",
        "width": "150px",
        borderRadius: "10px"
      },
      alertDivWithSearchTop: {
        display: "flex",
        marginBottom: '10px',
        marginTop: '15px',
        gap: '20px',
        "justify-content": "space-between",
        "align-items": "center"
      },
      rightPanel: {
        display: "flex"
      },
      toggle: {
        // paddingTop: "10px",
        display: "flex"
      },
      toggleText: {
        paddingTop: "6px"
      },
      tableSection: {
        borderRadius: "6px",
        marginTop:"25px"
      },
      filterDiv:{
        background:"white",
        padding:"10px"
      },
      filterInput:{
        width:"285px",
        display:'flex',
        alignItems: 'center',
        padding:"8px 12px",
        backgroundColor:'white',
        borderRadius:'6px',
        marginBottom:'15px'
      },
      childRadioButtons: {
        paddingLeft: theme.spacing(3), // Adjust the padding here
      },
      datePickerInput: {
        borderRadius:'6px',
        padding: "15px",
        margin: "10px"
      },
      filterSubmitBtn: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        color: "white",
        height: "50px",
        width: "150px",
        borderRadius: "10px",
        marginRight: theme.spacing(2)
      },
      clearBtnStyle: {
        "text-transform": "none",
        height: "50px",
        width: "150px",
        borderRadius: "10px"
      },
      filterApplyButtons:{
        marginTop: "20px",
        display:"flex",
        justifyContent:"space-between"
      },
      mainRadioContent:{
        width:"100%",
        marginLeft: '40px'
      },
    btnText: {
        background: "#fff",
        color: "rgb(64, 109, 127)",
        minWidth: "155px",
        height: "53px",
        border: 'solid 1px rgb(64, 109, 127)',
        boxShadow: 'none',
        padding: '8px 15px',
        fontWeight: 600,
        'text-transform': 'none'
    },
    dialogWrapper: {
        "& [role='dialog']": {
            padding: '30px',
            borderRadius: '16px',
            width: '600px',
            height: "500px"
        }
    },
    dialogTitle: {
        textAlign: "left" as any,
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headingStyle: {
        textAlign: 'center' as any,
        '& h2': {
            fontWeight: 700,
        },
        fontSize: "16px"
    },
    downloadIcon: {
        cursor: "pointer"
    },
    clearBtn: {
        margin: "0px 10px",
        color: '#757575',
    },
    btnWrapper: {
        justifyContent: 'end',
    },
    modelPrimaryBtn: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        textTransform: "none" as any,
        color: "white",
        borderColor: '#3b8da2',
        padding: '10px 0px',
        width: '150px',
        borderRadius: "8px"
    },
    cancelBtnStyle: {
        color: '#757575', borderRadius: "8px", textTransform:"none" as any, borderColor:"#757575", padding: '10px 0px', width: '150px',
    },
});

export default withStyles(useStyles)(withTranslation()(TablesTab));